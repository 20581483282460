import { createApp } from 'vue'
import App from './App.vue'
import { Button,Image,Badge,Tabs,Tab,Barrage,Card,NoticeBar,Icon, Space } from 'vant';
import 'vant/lib/index.css';


createApp(App)
  .use(Space)
  .use(Button)
  .use(NoticeBar)
  .use(Image)
  .use(Badge)
  .use(Tabs)
  .use(Tab)
  .use(Icon)
  .use(Barrage)
  .use(Card)
  
  .mount('#app')
