<template>
  
  <van-notice-bar
  left-icon="volume-o"
  text="无论我们能活多久，我们能够享受的只有无法分割的此刻，此外别无其他。"
/>


<van-tabs v-model:active="active">
  <van-tab title="介绍">
    <div class="container">
      <van-image class="imagehd"
  round
  width="15rem"
  height="15rem"
  :src="require('../assets/head.png')"
/>
<h3>我是lilknee，这里是我个人网站，下面是我玩的社交平台</h3>
  <van-space class="buttom" :size="20">

    <van-icon class="cursor" size="35" :name="require('../assets/bilbil.png')" @click="redirectToWebsite('https://space.bilibili.com/346307089')" />
    <van-icon class="cursor" size="35" :name="require('../assets/github.png')" @click="redirectToWebsite('https://github.com/lilknee')" />
    <van-icon class="cursor" size="35" :name="require('../assets/csdn.png')" @click="redirectToWebsite('https://blog.csdn.net/weixin_56866072')" />
    <van-icon class="cursor" size="35" :name="require('../assets/zhihu.png')" @click="redirectToWebsite('https://www.zhihu.com/people/wo-ai-fang-pi-96')" />
</van-space>
    
  </div>
 </van-tab>
  <van-tab title="写过的项目"><van-space class="buttom" wrap><van-card class="card1"

  desc="一个对接chatgpt3.5接口的智能对话小程序，开通了流量主，我自行推广到了6000用户，目前广告收入400+"
  title="智能对话小程序"
  :thumb="require('../assets/talk.jpg')"
/>
<van-card class="card1" wrap

  desc="自己做了一种仿midjourney生成方式的绘画小程序，用的时midjourney的官方api，买了6块钱，但是源码卖了一份收入100元，推广不起来，已经搁置，画面因为没用组件，看起来很简陋，但是该有的都有"
  title="midjourney智能绘画小程序"
  :thumb="require('../assets/drawer.jpg')"
/>
<van-card class="card1"

  desc="用了vant组件和lin-ui组件做了一个可以管理商品和自动发货的商城小程序，里面放了我自己写的小程序的源码，便宜卖，目前最得意的作品，收入0，我在b站发了接近4小时的开发过程视频，没人看"
  title="可以管理商品和自动发货的商城小程序"
  :thumb="require('../assets/store.jpg')"
/>
</van-space>
</van-tab>
  <van-tab title="开源下载"></van-tab>
  <van-tab title="一些文档"></van-tab>
</van-tabs>



  
</template>

<script>
import { ref } from 'vue';
import 'vant/lib/index.css';


export default {
  setup() {
    const active = ref(0);
    return { active };
  },
  methods: {
    redirectToWebsite(url) {
      // 使用 JavaScript 来执行页面跳转
      window.location.href = url; // 替换为你要跳转的网页 URL
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  margin: 0 auto;
  margin-top: 50px;

  height: 370px;
  width: 280px;
  background-color: white; /* 设置背景色为纯白色 */
  border: 3px solid #ccc; /* 添加边框，可根据需要调整颜色和样式 */
  padding: 20px; /* 可选的内边距，根据需要进行调整 */
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.1); /* 添加阴影，增加层次感 */
  border-radius: 18px; /* 可选的圆角，根据需要进行调整 */
}
.card1{
 

  --van-card-thumb-size: 100px
}
.buttom{
  margin-bottom: 0px;
}
.cursor{
  cursor: pointer; 
}
.imagehd{
  margin-left: 11px;

}
.bilbil{
  --van-button-icon-size: 12em;
}
.child {
    width: 40px;
    height: 40px;
    background: #f2f3f5;
    border-radius: 4px;
  }

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
